import { api } from "@/axios/axios";
// 購物車
const shopCartApi = {
  // 新增
  async fcAddShopList(data) {
    try {
      const url = "/shopCart";
      const res = await api.post(url, data);
      return res.data;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  // 獲取
  async fcGetShopList() {
    try {
      const url = "/shopCart";
      const res = await api.get(url);
      return res.data;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  // 更新
  async fcUpdateShopList(data, id) {
    try {
      const url = "/shopCart/" + id;
      const res = await api.put(url, data);
      return res.data;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  // 刪除
  async fcDeleteShopList(id) {
    try {
      const url = "/shopCart/" + id;
      const res = await api.delete(url);
      return res.data;
    } catch (err) {
      return Promise.reject(err);
    }
  },
};
export default shopCartApi;
