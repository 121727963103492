// 全域功能
exports.install = function (Vue) {
  Vue.prototype.success = function (meg) {
    this.$notify({
      title: "成功",
      message: meg,
      type: "success",
      offset: 100,
    });
  };
  Vue.prototype.error = function (meg) {
    this.$notify({
      title: "失敗",
      message: meg,
      type: "error",
      offset: 100,
    });
  };
};
