<template>
  <div id="app">
    <el-container
      v-loading.fullscreen.lock="loading"
      element-loading-text="錯誤"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <!-- 頂端bar -->
      <div class="topbar">
        <div class="nav">
          <ul>
            <li v-if="!this.$store.getters.getUser">
              <el-button type="text" @click="fcLogin()">{{
                $t("system.login")
              }}</el-button>
              <span class="sep">|</span>
              <el-button type="text" @click="register = true">{{
                $t("system.register")
              }}</el-button>
            </li>
            <li v-else>
              {{ $t("system.welcome") }}
              <el-popover placement="top" width="180" v-model="visible">
                <p>{{ $t("system.sure_logout") }}</p>
                <div style="text-align: right; margin: 10px 0 0">
                  <el-button size="mini" type="text" @click="visible = false">{{
                    $t("system.cancel")
                  }}</el-button>
                  <el-button type="primary" size="mini" @click="fcLogOut()">{{
                    $t("system.sure")
                  }}</el-button>
                </div>
                <el-button type="text" slot="reference">{{
                  this.$store.getters.getUser
                }}</el-button>
              </el-popover>
            </li>
            <li>
              <router-link to="/order">{{ $t("system.order") }}</router-link>
            </li>
            <li>
              <router-link to="/shopCart">
                <i class="el-icon-shopping-cart-full"></i>
                {{ $t("system.cart") }}
                <span class="num">({{ getAllNum }})</span>
              </router-link>
            </li>
            <li>
              <el-select
                v-model="value"
                size="mini"
                :placeholder="lang"
                @change="fcLang(value)"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </li>
          </ul>
        </div>
      </div>
      <el-header>
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
          active-text-color="#000000"
          router
        >
          <div class="logo">
            <router-link to="/">
              <img src="@/assets/logo.png" alt="" />
            </router-link>
          </div>
          <el-menu-item index="/">{{ $t("system.page") }}</el-menu-item>
          <el-menu-item index="/products">{{
            $t("system.products")
          }}</el-menu-item>
          <el-menu-item index="/about">{{ $t("system.about") }}</el-menu-item>
          <div class="search">
            <el-input type="text" v-model="search" size="medium">
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="fcSearch()"
              ></el-button>
            </el-input>
          </div>
        </el-menu>
      </el-header>
      <el-main>
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </el-main>
      <Login></Login>
      <Register :register="register" @changeReigster="fcRegister"></Register>
      <el-footer>
        <div class="draw">LOUIS&LOUIS&LOUIS&LOUIS</div>
        <Footer></Footer>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import Login from "@/components/Login.vue";
import Register from "@/components/Register.vue";
import Footer from "@/components/Footer.vue";
import { mapActions, mapGetters } from "vuex";
import userApi from "@/api/userApi";
import shopCartApi from "@/api/shopCartApi";
import router from "./router";

export default {
  name: "App",
  components: {
    Login,
    Register,
    Footer,
  },
  data() {
    return {
      activeIndex: "",
      search: "",
      visible: false,
      register: false,
      options: [
        {
          value: "En",
          label: this.$t("system.en"),
        },
        {
          value: "Tw",
          label: this.$t("system.tw"),
        },
      ],
      value: "",
    };
  },
  beforeUpdate() {
    this.activeIndex = this.$route.path;
  },
  created() {
    if (localStorage.getItem("user")) {
      this.setUser(JSON.parse(localStorage.getItem("user")));
    }
    this.fcGetCart();
    if (!localStorage.getItem("language")) {
      this.value = "Tw";
    }
  },

  computed: {
    ...mapGetters(["getUser", "getAllNum", "getLoading"]),
    // 整頁loading
    loading: {
      get() {
        return this.$store.getters.getLoading;
      },
      set(val) {
        this.setLoading(val);
      },
    },
    lang() {
      let lang = localStorage.getItem("language");
      if (lang == "Tw") {
        lang = "中文";
      }
      return lang;
    },
  },
  methods: {
    ...mapActions(["setLoginItem", "setUser", "fcSetShopCart", "setLoading"]),
    // 獲取購物車
    fcGetCart() {
      shopCartApi
        .fcGetShopList()
        .then((res) => {
          this.fcSetShopCart(res);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    // 切換頁籤
    handleSelect() {
      this.activeIndex = this.$route.path;
    },
    // 登入彈窗
    fcLogin() {
      this.setLoginItem(true);
    },
    // 登出彈窗
    fcLogOut() {
      userApi
        .fcLogOut()
        .then((res) => {
          if (res.code == 200) {
            localStorage.removeItem("user");
            localStorage.removeItem("access_token");
            this.setUser("");
            this.visible = false;
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    // 註冊彈窗
    fcRegister(val) {
      this.register = val;
    },
    //  搜尋
    fcSearch() {
      router.push({
        path: "/products",
        query: { name: this.search },
      });
      this.search = "";
    },
    // 語言切換
    fcLang(value) {
      localStorage.setItem("language", value);
      return history.go(0);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./assets/css/mixins.scss";
.topbar {
  width: 100%;
  height: 40px;
  background-color: #3d3d3d;
  margin-bottom: 20px;
  .nav {
    max-width: 1000px;
    margin: 0 auto;
    ul {
      display: flex;
      justify-content: flex-end;
    }
    li {
      height: 40px;
      margin-left: 20px;
      color: $main-color-text;
      @include font(14px, 400, 40px, 1px, center);
      .num {
        color: $main-color;
        font-weight: bold;
      }
      .el-button {
        color: $main-color-text;
      }
      a {
        color: $main-color-text;
      }
    }
    .sep {
      color: $main-color-text;
      font-size: 12px;
      margin: 0 5px;
    }
    .el-button {
      &:hover {
        color: $main-white;
      }
    }
    a {
      &:hover {
        color: $main-white;
      }
    }
    .shopCart {
      width: 120px;
      background: #424242;
      &:hover {
        background: $main-white;
        a {
          color: $main-color-text-hover;
        }
      }
    }
    .shopCart-full {
      width: 120px;
      background: $main-color-text-hover;
      a {
        color: white;
      }
    }
  }
  @include media(mobile) {
    .nav {
      li {
        font-size: 12px;
      }
    }
  }
}
.el-header {
  height: auto !important;
  .el-menu {
    max-width: 1000px;
    margin: 0 auto;
    @include flex-box(initial, center, wrap, row);
    .logo {
      width: 70px;
      margin: 0 30px 10px 0;
      a {
        display: inline-block;
        img {
          @include w_h(100%, auto);
          border-radius: 5px;
        }
      }
    }
    .search {
      position: absolute;
      right: 0;
    }
  }
  @include media(mobile) {
    .el-menu {
      .logo {
        width: 50px;
        margin: 0 10px 10px 0;
      }
      .search {
        position: relative;
        width: 100%;
        margin: 10px 0;
      }
    }
  }
}
.el-main {
  padding: 0;
  margin-bottom: 100px;
}
.el-footer {
  padding: 0;
  position: relative;
}
::v-deep .el-loading-spinner {
  color: $main-white;
  .el-icon-loading:before {
    font-size: 60px;
  }
  .el-loading-text {
    color: $main-white;
    margin: 10px 0;
    font-size: 24px;
  }
}
::v-deep .el-input--mini {
  .el-input__inner {
    width: 75px;
    color: $main-white;
    background: transparent;
    border: none;
  }
}
.draw {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  font-size: 144px;
  color: #e9e9e9;
  transition: 0.5 ease;
  animation: draw 36s linear infinite;
  line-height: 1.7;
  z-index: -1;
}
@keyframes draw {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
</style>
