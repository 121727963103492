<template>
  <div>
    <div class="content_module">
      <el-dialog
        :title="$t('system.login')"
        :visible.sync="loginItem"
        width="13%"
        top="30vh"
        :close-on-click-modal="false"
        lock-scroll
        center
      >
        <el-form
          :model="form"
          :rules="rules"
          status-icon
          label-width="0px"
          ref="ruleForm"
          class="demo-ruleForm"
        >
          <el-form-item prop="username">
            <el-input
              type="text"
              autocomplete="off"
              prefix-icon="el-icon-user"
              :placeholder="$t('system.account')"
              v-model="form.username"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              type="password"
              autocomplete="off"
              prefix-icon="el-icon-key"
              :placeholder="$t('system.password')"
              show-password
              v-model="form.password"
            >
              ></el-input
            >
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="fcSubmit()">{{
            $t("system.login")
          }}</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import userApi from "@/api/userApi";

export default {
  name: "LoginItem",
  data() {
    // 帳號檢查規則
    let validateName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("請輸入帳號"));
      }
      const nameRule = /^[a-zA-Z][a-zA-Z0-9_]{4,16}$/;
      if (nameRule.test(value)) {
        this.$refs.ruleForm.validateField("checkPass");
        return callback();
      } else {
        return callback(new Error("字母開頭6-16字之間"));
      }
    };
    // 密碼檢查規則
    let validatePassword = (rule, value, callback) => {
      if (value === "") {
        return callback(new Error("請輸入密碼"));
      }
      const passwordRule = /^[\w_-]{6,16}$/;
      if (passwordRule.test(value)) {
        this.$refs.ruleForm.validateField("checkPass");
        return callback();
      } else {
        return callback(new Error("6-16字數字皆可"));
      }
    };
    return {
      form: {
        username: "louis",
        password: "a123456",
      },
      rules: {
        username: [{ validator: validateName, trigger: "blur" }],
        password: [{ validator: validatePassword, trigger: "blur" }],
      },
    };
  },
  computed: {
    ...mapGetters(["getLoginItem"]),
    loginItem: {
      get() {
        return this.getLoginItem;
      },
      set(val) {
        this.$refs.ruleForm.resetFields();
        this.setLoginItem(val);
      },
    },
  },
  created() {
    this.fcInit();
  },

  methods: {
    ...mapActions(["setUser", "setLoginItem"]),
    // 初始化
    fcInit() {
      localStorage.getItem("user");
    },
    // 登入
    fcSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          userApi
            .fcLogin(this.form)
            .then((res) => {
              this.$refs.ruleForm.resetFields();
              localStorage.setItem("access_token", res.access_token);
              localStorage.setItem("user", JSON.stringify(res.username));
              this.setUser(JSON.parse(localStorage.getItem("user")));
              this.loginItem = false;
              this.success("登入成功");
            })
            .catch((err) => {
              this.error();
              return Promise.reject(err);
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/css/mixins.scss";
::v-deep .el-dialog--center {
  .el-dialog__body {
    padding: 10px 25px 0 25px;
  }
}
::v-deep .el-dialog {
  @include media(tablet) {
    width: 30% !important;
  }
  @include media(mobile) {
    width: 70% !important;
  }
}
</style>
