// 入口
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "./assets/css/reset.scss";

// ul樣式
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import global from "./global";
import i18n from "./plugins/vue-i18n";
import VueCompositionAPI from "@vue/composition-api";

Vue.use(global);
Vue.use(ElementUI);
Vue.use(VueCompositionAPI);

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
