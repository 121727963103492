export default {
  state: {
    shopCart: [],
  },
  getters: {
    // 獲取購物車值
    getShopCart(state) {
      return state.shopCart;
    },
    // 獲取購物總數量值
    getAllNum(state) {
      let allnum = 0;
      state.shopCart.forEach((item) => {
        allnum += item.shopCart.num;
      });
      return allnum;
    },
    // 獲取勾選數量值
    getCheck(state) {
      let checkNum = 0;
      state.shopCart.forEach((item) => {
        if (item.shopCart.check) {
          checkNum += item.shopCart.num;
        }
      });
      return checkNum;
    },
    // 獲取全部勾選值
    getAllCheck(state) {
      let checkAll = true;
      state.shopCart.forEach((item) => {
        if (!item.shopCart.check) {
          checkAll = false;
          return checkAll;
        }
      });
      return checkAll;
    },
    // 已勾選商品值
    getCheckProduct(state) {
      let isCheckProduct = [];
      state.shopCart.forEach((item) => {
        if (item.shopCart.check == true) {
          isCheckProduct.push(item);
        }
      });
      return isCheckProduct;
    },
    // 獲取購物總價值
    getTotalPrice(state) {
      let totalPrice = 0;
      state.shopCart.forEach((item) => {
        if (item.shopCart.check) {
          totalPrice += item.shopCart.num * item.shopCart.price;
        }
      });
      return totalPrice;
    },
  },
  mutations: {
    // 設置購物車
    fcSetShopCart(state, data) {
      state.shopCart = data;
    },
    // 添加商品至購物車
    fcAddShopCart(state, data) {
      state.shopCart.push(data);
    },
    // 增加商品數量
    fcAddShopNum(state, val) {
      state.shopCart.forEach((item) => {
        item.shopCart.num == val;
      });
    },
    // 刪除購物商品
    fcDeleteShopCart(state, id) {
      state.shopCart.forEach((item, index) => {
        if (item.id == id) {
          state.shopCart.splice(index, 1);
        }
      });
    },
    // 更新購物車
    fcUpdateShopCart(state, payload) {
      state.shopCart[payload.index]["shopCart"][payload.type] = payload.val;
    },
    // 勾選全部商品
    fcAllCheck(state, data) {
      state.shopCart.forEach((item) => {
        item.shopCart.check = data;
      });
    },
  },
  actions: {
    fcAddShopCart(context, data) {
      context.commit("fcAddShopCart", data);
    },
    fcSetShopCart(context, data) {
      context.commit("fcSetShopCart", data);
    },
    fcAddShopNum(context, val) {
      context.commit("fcAddShopNum", val);
    },
    fcDeleteShopCart(context, id) {
      context.commit("fcDeleteShopCart", id);
    },
    fcUpdateShopCart(context, payload) {
      context.commit("fcUpdateShopCart", payload);
    },
    fcAllCheck(context, data) {
      context.commit("fcAllCheck", data);
    },
  },
};
