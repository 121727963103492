<template>
  <div class="content_module">
    <div class="big_wrap">
      <div class="top">
        <div class="item">
          <a
            class="logo"
            href="https://gitlab.com/louisex222/project"
            target="_blank"
          >
            <img class="img" :src="img" alt />
          </a>
        </div>
        <div class="scroll_top" @click="clickScroll()">
          <p>TOP</p>
        </div>
      </div>
      <div class="middle">
        <div class="sitemap_wrap" v-for="(item, key) in siteMap" :key="key">
          <router-link class="title" :to="item.path">{{
            item.title
          }}</router-link>
        </div>
      </div>
    </div>
    <div class="copyright">
      <div class="left">
        <div class="item">
          <p>@2022 Designed by Louis</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterItem",
  data() {
    return {
      img: require("@/assets/gitlab.png"),

      siteMap: [
        {
          title: this.$t("system.page"),
          path: "/",
        },
        {
          title: this.$t("system.products"),
          path: "/products",
        },
        {
          title: this.$t("system.about"),
          path: "/about",
        },
      ],
    };
  },
  methods: {
    clickScroll() {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/css/mixins.scss";
.content_module {
  position: relative;
  width: 100%;
  max-height: 48.4375vw;
  .bg {
    @include img-cover;
    @include position(absolute, 0, 0, 0, 0);
    background: #252525;
    z-index: -1;
  }
  a {
    text-decoration: none;
  }
  .big_wrap {
    background: #14120f;
    padding: 3.65vw 13.125vw 0;
  }
  .middle {
    padding-top: 1.82vw;
    padding-bottom: 4vw;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #777777;
    .title {
      margin: 0 20px;
      color: #ffffff;
      @include font(calc(18 / 1920 * 100vw), bold, 1.15, 0.9px, initial);
    }
    @include media(tablet) {
      flex-wrap: wrap;
      padding-top: 3.82vw;
      .title {
        font-size: 18px;
      }
    }
    @include media(mobile) {
      padding-top: 23px;
      padding-bottom: 38px;
      flex-wrap: wrap;
      .title {
        font-size: 14px;
        margin: 0;
        padding: 15px 0;
      }
    }
  }
  .top {
    position: relative;
    width: 100%;
    @include box-center;
    .item {
      .logo {
        width: 5.42vw;
        display: inline-block;
        .img {
          max-width: 100%;
          height: auto;
          border-radius: 50%;
        }
      }
    }
    .scroll_top {
      position: absolute;
      width: 3.125vw;
      right: -1.04vw;
      top: 1.66vw;
      z-index: 1;
      p {
        @include font(calc(16 / 1920 * 100vw), bold, 1.15, 1px, initial);
        color: #ffffff;
        transform: rotate(270deg);
        cursor: pointer;
        transition: 0.5s ease;
        &::before {
          content: "";
          position: absolute;
          width: 2.65vw;
          height: 1px;
          background: #a0a0a0;
          left: -4.4vw;
          top: 0.36vw;
          transition: 0.5s ease;
        }
        &:hover {
          letter-spacing: 2px;
          transition: 0.5s ease;
          &::before {
            transition: 0.5s ease;
            left: -4.9vw;
          }
        }
      }
    }
    @include media(desktop) {
      .scroll_top {
        top: 1.73vw;
      }
    }
    @include media(tablet) {
      padding-top: 2.823vw;
      flex-wrap: wrap;
      .item {
        width: 50%;
        text-align: center;
        .logo {
          width: 40%;
          margin-bottom: 6.4375vw;
        }
      }
      .scroll_top {
        top: 30px;
      }
    }
    @include media(mobile) {
      padding-top: 40px;
      flex-wrap: wrap;
      .item {
        width: 100%;
        margin-bottom: 44px;
        .logo {
          width: 100px;
          margin-bottom: 11.4375vw;
        }
      }
      .scroll_top {
        top: auto;
        bottom: -80px;
        p {
          font-size: 16px;
          &::before {
            width: 51px;
            left: -65px;
            top: 8px;
          }
          &:hover {
            &::before {
              left: -70px;
            }
          }
        }
      }
    }
  }
  .copyright {
    @include box-center;
    position: relative;
    padding: 1.82vw 12.125vw 1.3vw;
    background: #1b1b1b;
    p {
      color: #ffffff;
      @include font(calc(14 / 1920 * 100vw), normal, 1.15, 0.7px, initial);
    }
    .left {
      position: relative;
      left: 1.04vw;
      display: flex;
      align-items: center;
      .item {
        &:first-child {
          margin-right: 1.25vw;
        }
      }
    }
    @include media(tablet) {
      padding: 2.82vw 55px 3.3vw;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .left {
        margin-bottom: 15px;
      }
    }
    @include media(mobile) {
      padding: 35px 37px 53px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p {
        font-size: 14px;
        line-height: 1.5;
      }
      .left {
        margin-bottom: 25px;
        flex-wrap: wrap;
        .item {
          width: 100%;
        }
      }
    }
  }
  @include media(tablet) {
    height: auto;
    max-height: 100%;
    .big_wrap {
      padding: 4.65vw 55px 0;
    }
  }
  @include media(mobile) {
    height: auto;
    .big_wrap {
      padding: 40px 37px 0;
    }
  }
}
</style>
