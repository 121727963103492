import Vue from "vue";
import Router from "vue-router";
import store from "@/store";
Vue.use(Router);

const routes = [
  {
    path: "/",
    name: "homePage",
    component: () => import("../view/Home.vue"),
  },
  {
    path: "/order",
    name: "orderPage",
    component: () => import("../view/Order.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/confirm",
    name: "confirmPage",
    component: () => import("../view/Confirm.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/shopCart",
    name: "shopCartPage",
    component: () => import("../view/ShopCart.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/products/detail",
    name: "detailPage",
    component: () => import("../view/Details.vue"),
  },
  {
    path: "/products",
    name: "productsPage",
    component: () => import("../view/Products.vue"),
  },
  {
    path: "/error",
    name: "errorPage",
    component: () => import("../view/Error.vue"),
  },
  {
    path: "/about",
    name: "aboutPage",
    component: () => import("../view/About.vue"),
  },
];

const router = new Router({
  mode: "history",
  base: __dirname,
  routes,
});
// 全域守衛
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("access_token");
  if (to.meta.requiresAuth === true) {
    if (!token) {
      store.dispatch("setLoginItem", true);
      next({ name: "homePage" });
    } else {
      next();
    }
  }
  next();
});
const vueRouterPush = Router.prototype.push;
Router.prototype.push = function push(to) {
  return vueRouterPush.call(this, to).catch((err) => err);
};
export default router;
