import { api } from "@/axios/axios";
// 會員
const userApi = {
  // 登出
  async fcLogin(data) {
    try {
      const url = "/auth/login";
      const res = await api.post(url, data);
      return res.data;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  // 登出
  async fcLogOut() {
    try {
      const url = "/auth/logout";
      const res = await api.get(url);
      return res.data;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  // 註冊
  async fcRegister(data) {
    try {
      const url = "/auth/register";
      const res = await api.post(url, data);
      return res.data;
    } catch (err) {
      return Promise.reject(err);
    }
  },
};
export default userApi;
