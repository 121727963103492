import Vue from "vue";
import VueI18n from "vue-i18n";

import En from "@/i18n/en.json";
import Tw from "@/i18n/tw.json";

Vue.use(VueI18n);
// 多語設定
const lang = localStorage.getItem("language") || "Tw";
const i18n = new VueI18n({
  locale: lang,
  messages: {
    En,
    Tw,
  },
});
export default i18n;
