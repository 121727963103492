import axios from "axios";
import VueCookies from "vue-cookies";
import router from "@/router";

const api = axios.create({
  baseURL: "http://localhost:8080",
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json",
  },
  timeout: 10000,
});
const token = VueCookies.get("token");
if (token) {
  api.defaults.headers.common.Authorization = "Bearer " + token;
}
api.interceptors.request.use(
  // 回傳token驗證
  (config) => {
    config.headers["Authorization"] = `Bearer ${localStorage.getItem(
      "access_token"
    )}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  // 未開伺服器跳轉error頁
  (res) => {
    if (res.data.code == "ERR_NETWORK") {
      router.push({ path: "/error" });
    }
    return res;
  },
  (err) => {
    router.push({ path: "/error" });
    return Promise.reject(err);
  }
);

export { api };
