export default {
  state: {
    user: "",
    loginShow: false,
    loading: false,
  },
  getters: {
    getUser(state) {
      return state.user;
    },
    getLoginItem(state) {
      return state.loginShow;
    },
    getLoading(state) {
      return state.loading;
    },
  },
  mutations: {
    setUser(state, data) {
      state.user = data;
    },
    setLoginItem(state, data) {
      state.loginShow = data;
    },
    setLoading(state, data) {
      state.loading = data;
    },
  },
  actions: {
    setUser(context, data) {
      context.commit("setUser", data);
    },
    setLoginItem(context, data) {
      context.commit("setLoginItem", data);
    },
    setLoading(context, data) {
      context.commit("setLoading", data);
    },
  },
};
